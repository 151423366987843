<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
        exportFilename="relatorio_iptu[GUIAS_IMPLANTADAS]"
           csvSeparator=";"
          ref="dt"
          :value="unidades"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="8"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0">Guias Implantadas</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar"
                />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column
            field="razao_social"
            header="Empresa"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
          </Column>

          <Column
            field="controle_re"
            header="Contorole RE"
            :sortable="true"
            headerStyle="width:30%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">controle_re</span>
              {{ slotProps.data.controle_re }}
            </template>
          </Column>

          <Column
            field="ordem_sap"
            header="Ordem SAP"
            :sortable="true"
            headerStyle="width:30%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">ordem_sap</span>
              {{ slotProps.data.ordem_sap }}
            </template>
          </Column>

          <Column
            field="regional"
            header="Regional"
            :sortable="true"
            headerStyle=" min-width:10rem;"
          >
          </Column>

          <Column
            field="cidade"
            header="Cidade"
            :sortable="true"
            headerStyle="width:30%; min-width:10rem;"
          >
          </Column>

          <Column
            field="uf"
            header="UF"
            :sortable="true"
            headerStyle="width:30%; min-width:10rem;"
          >
          </Column>

          <Column
            field="inscricao_imobiliaria"
            header="Inscrição Imobilária"
            :sortable="true"
            headerStyle=" min-width:15rem;"
          >
          </Column>
          <Column
            field="tipo"
            header="Tipo"
            :sortable="true"
            headerStyle=" min-width:10rem;"
          >
          </Column>

          <Column
            field="data_referencia"
            header="Data Refereência"
            :sortable="true"
            headerStyle=" min-width:15rem;"
          >
          </Column>

          <Column
            field="data_vencimento"
            header="Data Vencimento"
            :sortable="true"
            headerStyle=" min-width:14rem;"
          >
          </Column>
          <Column
            field="total"
            header="Total"
            :sortable="true"
            headerStyle=" min-width:15rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">total</span>
              {{ formatCurrency(slotProps.data.total) }}
            </template>
          </Column>
          <Column
            field="total_desconto"
            header="Total c/ Desconto"
            :sortable="true"
            headerStyle=" min-width:15rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">total_desconto</span>
              {{ formatCurrency(slotProps.data.total_desconto) }}
            </template>
          </Column>

          <Column
            field="id"
            header="Protocolo Envio"
            :sortable="true"
            headerStyle=" min-width:15rem;"
          >
          </Column>
          <Column
            field="nome_download"
            header="Nome Dowload"
            :sortable="true"
            headerStyle=" min-width:15rem;"
          >
          </Column>

          <Column
            field="data_download"
            header="Data Dowload"
            :sortable="true"
            headerStyle=" min-width:15rem;"
          >
          </Column>

          <Column header="Download" headerStyle=" min-width:25rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-download"
                class="p-button-rounded p-button-success mr-2"
                @click="download(slotProps.data)"
              />
            </template>
          </Column>

          <Column
            field="link"
            header="Link"
            :sortable="true"
            headerStyle=" min-width:25rem;"
          >
            <template #body="slotProps">
              {{ slotProps.data.link + "/"+ token}}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      token: localStorage.getItem('token'),
      dado_pendente: [
        { label: "Limpar Filtro", value: "" },
        { label: "Contrato RE", value: "1" },
        { label: "Inscrição Imobiliária", value: "2" },
        { label: "Ordem SAP", value: "3" },
        { label: "Endereço", value: "4" },
        { label: "Área", value: "5" },
        { label: "Tipo", value: "6" },
        { label: "Regional", value: "7" },
        { label: "Regra Pagamento", value: "8" },
      ],
      parcelas: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
        { label: "11", value: 11 },
        { label: "12", value: 12 },
      ],
      dado: "",
      arquivo: "",
      unidades: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      unidade: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      id_empresa: "",
      id_unidade: "",
      parcela: "",
      id_tipo: "",
      data_referencia: "",
      data_vencimento: "",
      total: "",
      boleto: "",
      faturas: [],
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarUnidades();
  },
  methods: {
    listarAreas() {
      this.axios
        .post("/areas/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] };
            options.push(option);
          });
          this.areas = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    trocarCidades() {
      console.log(this.unidade.id_estado);
      this.listarCidades(this.unidade.id_estado);
    },

    filtrar() {
      let dados = {
        filtro: this.dado,
      };
      this.axios
        .post("/unidades/listar", dados)
        .then((response) => {
          if (Object.keys(response.data.dados).length == 0) {
            this.unidades = [response.data.dados];
          } else {
            this.unidades = response.data.dados;
          }
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    listarTipos() {
      this.axios
        .post("/tipos-faturas/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] };
            options.push(option);
          });
          this.tipos = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    listarUnidades() {
      this.axios
        .post("/faturas/listar")
        .then((response) => {
          this.unidades = response.data.dados;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.unidade = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    onChangeFileUpload() {
      this.arquivo = this.$refs.arquivo.files[0];
    },
    salvarFatura() {
      this.submitted = true;

      let formData = new FormData();
      formData.append("arquivo", this.arquivo);

      let dados = {
        id_empresa: this.id_empresa,
        id_unidade: this.id_unidade,
        id_tipo: this.id_tipo,
        data_referencia: this.data_referencia,
        data_vencimento: this.data_vencimento,
        total: this.total,
        boleto: this.boleto,
        parcela: this.parcela,
      };

      formData.append("dados", JSON.stringify(dados));

      if (this.unidade.id) {
        this.axios
          .post("/unidades/editar", this.unidade)
          .then(() => {
            this.listarFatura();
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        this.axios
          .post("/faturas/adicionar", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.listarFatura();
            this.productDialog = false;
            this.unidade = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    download(fatura) {
      let url = { ...fatura }
      window.open(url.link+"/"+this.token);
    },
    editProduct(fatura) {
      this.productDialog = true;
      this.fatura = { ...fatura };
      this.id_empresa = this.fatura.id_empresa;
      this.id_unidade = this.fatura.id_unidade;
      this.id_tipo = this.fatura.id_tipo;
      this.data_referencia = this.fatura.data_referencia;
      this.data_vencimento = this.fatura.data_vencimento;
      this.total = this.fatura.total;
      this.boleto = this.fatura.boleto;
      this.parcela = this.fatura.parcela;
    },
    apagarFaturaModal(fatura) {
      this.fatura = fatura;
      this.deleteProductDialog = true;
    },
    apagarFatura() {
      this.axios
        .post("/faturas/apagar", this.unidade)
        .then(() => {
          this.listarFatura();
          this.deleteProductDialog = false;
          this.unidade = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Fatura apagada com sucsso!",
            life: 3000,
          });
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/unidades/apagar", obj[key])
          .then(() => {
            this.listarFatura();
            this.deleteProductDialog = false;
            this.unidade = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Faturas apagada com sucesso!",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
